<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title color="dark">{{ item.item }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
       <ion-item lines="none" class="label-item">
         <ion-label>Avance de obra</ion-label>
         {{avanceObra}}%
       </ion-item>
       <ion-item lines="none" class="label-item">
         <ion-progress-bar :value="progressBar" :color="colorAvance"></ion-progress-bar>
       </ion-item>
      <p>
        <ion-text>{{ item.description }}</ion-text>
      </p>
      <ion-item lines="none" class="equipo-item">
        <ion-label class="label-item">Cantidad:</ion-label>
        <ion-note slot="end">{{ item.cantidad/100}}</ion-note>              
      </ion-item>
      <ion-item lines="none" class="equipo-item">
        <ion-label class="label-item">Unidad:</ion-label>
        <ion-note slot="end">{{ item.unidad}}</ion-note>              
      </ion-item>
      <ion-item v-if="user.role =='empleado'" lines="none" class="equipo-item">
        <ion-label class="label-item">Precio</ion-label>
        <ion-note slot="end">$ {{ item.precio_unitario/100}}</ion-note> 
      </ion-item>
      <ion-item v-if="user.role =='admin'" lines="none" class="equipo-item">
        <ion-label class="label-item">Total: </ion-label>
        <ion-note slot="end">$ {{ (item.precio_unitario/100)*(item.cantidad/100)}}</ion-note>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonItem,
  IonLabel,
  IonNote,
  IonProgressBar
} from "@ionic/vue";
import { mapGetters } from "vuex";
export default {
  name: "item-overview",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonText,
    IonItem,
    IonLabel,
    IonNote,
  IonProgressBar
  },
  computed: {
    ...mapGetters("item", ["item"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("actividad", ["actividades"]),
    actividadesCant(){
      let actividades = this.actividades.length;
      return actividades
    },
    progressBar(){
      let cantidad = this.item.cantidad/100;
      let avance = ((((this.actividadesCant - cantidad)*100)/cantidad)/100) + 1;
      return avance;
    },
    avanceObra (){
      let avanceObra = this.progressBar * 100;
      return avanceObra;
    },
    colorAvance(){
      let color = "";
      if(this.avanceObra <= 25){
        color = "danger";
      }else if(this.avanceObra >25 && this.avanceObra <=50){
        color = "warning"
      }else if(this.avanceObra >50 && this.avanceObra <=99){
        color="secondary"
      }else if(this.avanceObra === 100){
        color = "success"
      }
      return color;
    }

  },
  data() {
    return{
      avance:0.5
    }
  }
};
</script>
<style scoped>
p{
  text-align: justify;
}
.equipo-item{
  height: 40px;
}
.label-item{
  height: 30px;
}
.equipo-item div.item-inner {
    border-bottom: none;
  }
.equipo-item .label-item{
  width:90%;
  font-size: 14px;
}
.equipo-item .label-cantidad{
  width:10%;
  font-size: 14px;
}
ion-note{
  font-size:16px;
}
</style>
