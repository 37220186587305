<template>
  <base-layout>
    <div class="container-detail">
      <ion-card v-if="!getItem">
        <ion-card-header>
          <ion-card-title color="warning">No Encontrado</ion-card-title>
        </ion-card-header>
        <ion-card-content> No se puede encontrar el item </ion-card-content>
      </ion-card>
      <item-overview v-else></item-overview>
      <actividad-list></actividad-list>
      <ion-button type="button" fill="clear" @click="newActividad">
        <ion-icon slot="start" :icon="addCircleOutline"></ion-icon>
        <ion-label>Agregar Actividad</ion-label>
      </ion-button>
      <ion-button type="button" fill="clear" @click="regresar">
        <ion-icon slot="start" :icon="arrowUndoOutline"></ion-icon>
        <ion-label>Regresar</ion-label>
      </ion-button>
    </div>
  </base-layout>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonLabel,
} from "@ionic/vue";
import { addCircleOutline, arrowUndoOutline} from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";
import ItemOverview from "../components/item/ItemOverview.vue";
import ActividadList from "../components/actividad/ActividadList.vue";
export default {
  name: "item-detail-page",
  components: {
    ItemOverview,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
    ActividadList,
  IonIcon,
  IonLabel
  },
  data() {
    return {
      addCircleOutline,
      arrowUndoOutline
    };
  },
  created() {},
  computed: {
    ...mapGetters("item", ["items", "item","itemId"]),
    getItem() {
      this.retrieveItem(this.itemId);
      return this.item;       
    },
  },  
  methods: {
    ...mapActions("item", ["retrieveItem", "setItemId"]),
    async newActividad() {
      await this.setItemId(this.itemId);
      this.$router.replace("/add-actividad/Nueva");
    },
    regresar() {
      this.$router.push("/items/Items");
    }
  },
};
</script>
<style scoped>
.container-detail {  
  padding: auto;
  align-items: center;
  margin-top: 10%;
}
ion-card {
  margin: 1em;
}
</style>
