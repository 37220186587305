<template>
  <ion-list>
    <actividad-list-item v-for="item in actividades" :key="item.id" :item="item"></actividad-list-item>  
  </ion-list>
</template>

<script>
import { IonList} from '@ionic/vue';
import ActividadListItem from './ActividadListItem.vue';
import { mapGetters } from 'vuex';
export default {
    nam: 'actividad-list',
    components: {
        IonList,
        ActividadListItem
    },
    computed: {
        //...mapGetters('item',['item']),
        ...mapGetters('actividad',['actividades'])
    },    
}
</script>